.container {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  margin-top: -12rem;

  .projectDetails {
    flex-wrap: nowrap !important;
  }
  .card {
    height: 100%;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #D8D8D8;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1) !important;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 5px 5px 5px 5px;

    &:hover{
      background-color: #FFFFFF;
      box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.36)!important;
    }
  }
}
